.maintitle {
  font-size: 24px;
  font-weight: 700;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

body {
  padding: 30px 60px;
}

.para {
  font-size: 18px;
  line-height: 30px;
}

ol,
ul,
menu {
  list-style: decimal;
}

#listview {
  list-style: lower-roman;
}

.bullet {
  list-style: disc
}